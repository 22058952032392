import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';

import React, { useEffect, useRef } from 'react';
import {
  ImgProvider,
  RENDER_IMAGE,
  RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY,
} from '@wix/communities-image-lib';
import { I18nextProvider } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import ComponentsProvider from '../../../common/components/components-provider';
import PermissionsProvider from '../../../common/components/permissions-provider';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { useSelector } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import {
  getIsCreatedWithResponsiveEditor,
  getPinterestEnabled,
} from '../../../common/selectors/app-settings-selectors';
import { buildPlaceholderSrc } from '../../../common/services/build-placeholder-src';
import { initI18n } from '../../../common/services/init-i18n';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import pinterest from '../../../common/services/pinterest';
import {
  getIsMobile,
  getLanguage,
  isEditor as getIsEditor,
  isSeo as getIsSeo,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import PostListMasonry from '../../components/post-list/post-list-masonry';
import PostListMobile from '../../components/post-list/post-list-mobile';
import PostListSimple from '../../components/post-list/post-list-simple';
import PostListItem from '../../components/post-list-item';
import SideBySideMobile from '../../components/post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../../components/post-list-item/mobile/text-on-image';
import Router from '../router';
import styles from './app-root.scss';

const AppRoot: React.FC = () => {
  useAutoHeight();
  useInitPinterest();
  const i18n = useI18n();
  const { isMobile, isSeo } = useSelector((state) => ({
    isSeo: getIsSeo(state),
    isMobile: getIsMobile(state),
  }));

  return (
    <ResponsiveListener dataHook="feed-page-root" className={styles.root}>
      <PermissionsProvider>
        <ComponentsProvider
          PostListProGallery={LazyPostListProGallery}
          PostListSimple={PostListSimple}
          PostListMasonry={PostListMasonry}
          PostListMobile={PostListMobile}
          PostListItem={PostListItem}
          Post={LazyPost}
          SideBySideMobile={SideBySideMobile}
          TextOnImageMobile={TextOnImageMobile}
        >
          <I18nextProvider i18n={i18n}>
            <ImgProvider
              renderMode={
                isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY
              }
              buildPlaceholderSrc={isMobile ? buildPlaceholderSrc : undefined}
              {...(isWebpSupported() ? { format: 'webp' } : {})}
            >
              <>
                <Router />
                <BlogPushNotifications />
              </>
            </ImgProvider>
          </I18nextProvider>
        </ComponentsProvider>
      </PermissionsProvider>
    </ResponsiveListener>
  );
};

function useI18n() {
  const { language, translations } = useSelector((state) => ({
    language: getLanguage(state),
    translations: getTranslations(state),
  }));
  const lastLanguageRef = useRef<string | undefined>(undefined);
  const i18nRef = useRef<ReturnType<typeof initI18n>>(undefined);

  if (lastLanguageRef.current !== language) {
    i18nRef.current = initI18n(language, translations);
    lastLanguageRef.current = language;
  }

  return i18nRef.current;
}

function useInitPinterest() {
  const { language, isMobile, isPinterestEnabled } = useSelector((state) => ({
    language: getLanguage(state),
    isMobile: getIsMobile(state),
    isPinterestEnabled: getPinterestEnabled(state),
  }));

  useEffect(() => {
    pinterest.init({ language, isMobile, isPinterestEnabled });

    return () => {
      pinterest.removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function useAutoHeight() {
  const shouldSetAutoHeight = useSelector(
    (state) => getIsEditor(state) && getIsCreatedWithResponsiveEditor(state),
  );

  useEffect(() => {
    if (shouldSetAutoHeight) {
      document.body.style.height = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const LazyPost = loadable(
  () =>
    import(
      /* webpackChunkName: "full-post-layout" */ '../../../common/components/post'
    ),
);

const LazyPostListProGallery = loadable(
  () =>
    import(
      /* webpackChunkName: "post-list-pro-gallery" */ '../../../common/components/post-list-pro-gallery'
    ),
);

export default withReduxStore(AppRoot);
