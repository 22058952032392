import { IWixAPI } from '@wix/yoshi-flow-editor';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_EDITOR,
  EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER,
} from '@wix/communities-blog-experiments';
import {
  ROUTE_ARCHIVE,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_HASHTAG,
  ROUTE_HOME,
  ROUTE_TAGS,
} from '../../feed-page/constants/routes';
import { AppState } from '../types';

type ViewMode = IWixAPI['window']['viewMode'];
type ViewModeLowercase = Lowercase<ViewMode>;

export const isFeedAdapterEnabled = (
  state: AppState,
  wixCodeWindowViewMode: ViewMode | ViewModeLowercase,
  route?: string,
) => {
  const viewMode = wixCodeWindowViewMode.toLowerCase() as ViewModeLowercase;
  const routesWithAdapter = [
    ROUTE_CATEGORY,
    ROUTE_CATEGORIES,
    ROUTE_HOME,
    ROUTE_TAGS,
    ROUTE_HASHTAG,
    ROUTE_ARCHIVE,
  ];

  if (route && !routesWithAdapter.includes(route)) {
    return false;
  }

  if (viewMode === 'site') {
    return isExperimentEnabled(
      state,
      EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER,
    );
  }

  return isExperimentEnabled(state, EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_EDITOR);
};
