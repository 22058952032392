import React from 'react';
import {
  getLastPage,
  PaginationType,
  Section,
} from '@wix/communities-blog-client-common';
import { connect } from '../../common/components/runtime-context';

import { MapRuntimeToProps } from '../../common/components/runtime-context/connect';
import {
  getCurrentPage,
  getEntityCount,
  getLastPageIndex,
  getPaginationPageSize,
  getPaginationType,
} from '../../common/selectors/pagination-selectors';

export type WithPaginationSettings = {
  paginationType: PaginationType;
  page: number;
  pageSize: number;
  lastPage: number;
  isValidPage: boolean;
  entityCount: number;
  showPagination: boolean;
};

export type WithPaginationSettingsOwnProps = {
  params?: { page?: string };
};

const mapRuntimeToProps =
  (
    section: Section,
  ): MapRuntimeToProps<
    WithPaginationSettings,
    WithPaginationSettingsOwnProps
  > =>
  (state, ownProps) => {
    const paginationType = getPaginationType(state, section);
    const showPagination = paginationType === PaginationType.MultiplePages;

    const page = showPagination
      ? Number(ownProps?.params?.page ?? getCurrentPage(state, 'posts'))
      : getLastPageIndex(state, 'posts');

    const pageSize = getPaginationPageSize(state, section);
    const entityCount = getEntityCount(state, 'posts');
    const lastPage = getLastPage(entityCount, pageSize);
    return {
      paginationType,
      page,
      pageSize,
      lastPage,
      isValidPage: page === 1 || (page > 1 && page <= lastPage),
      entityCount,
      showPagination,
    };
  };

export default function withPaginationSettings(section: Section) {
  return (WrappedComponent: React.ComponentType<WithPaginationSettings>) =>
    connect(mapRuntimeToProps(section))(WrappedComponent);
}
