import { trimEnd } from 'lodash';
import memoizeOne from 'memoize-one';
import {
  createPageUrl,
  SECTION_BLOG_PAGE,
} from '@wix/communities-blog-client-common';

export const createArchivePageUrl = (
  sectionUrl,
  year,
  month,
  page,
  multilingualQueryParam,
) =>
  createPageUrl(
    page,
    `${trimEnd(
      sectionUrl,
      '/',
    )}/archive/${year}/${month}${multilingualQueryParam}`,
  );

export const createHashtagPageUrl = (
  sectionUrl,
  hashtag,
  page,
  multilingualQueryParam,
) =>
  createPageUrl(
    page,
    `${trimEnd(sectionUrl, '/')}/hashtags/${hashtag}${multilingualQueryParam}`,
  );

export const createTagPageUrl = (
  sectionUrl,
  tag,
  page,
  multilingualQueryParam,
) =>
  createPageUrl(
    page,
    `${trimEnd(sectionUrl, '/')}/tags/${tag}${multilingualQueryParam}`,
  );

export const handleNavigation = memoizeOne(
  (path, isSite) =>
    ({ event, pageUrl, navigateInPreview }) => {
      if (!isSite) {
        event.preventDefault();
        navigateInPreview(pageUrl, SECTION_BLOG_PAGE);
      }
    },
);
